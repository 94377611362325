<template>
    <div class="font">
        <ayl-berad-nav :nav="nav"/>
        <div class="content-box mt20px">
            <div class="content-main">
                <ayl-table-with-query-object :table="table" @selection-change="handleSelectionChange">
                    <div slot="ctrl-button">
                        <el-button class="btn" style="margin-left: 20px" @click="onAdd">+ 发放工资</el-button>
                        <!-- <el-button class="ml40px" type="info" style="float: right;" plain>导出</el-button> -->
                        <el-button class="ml40px" type="info" style="float: right;" @click="onSubmit" plain>提交</el-button>
                    </div>
                </ayl-table-with-query-object >
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            // eslint-disable-next-line no-unused-vars
            const vm = this
            return {
                nav: [{
                    name: "工资管理"
                }],
                selectionData: [],     //提交勾选数组
                table: {
                    api: vm.$api_hw.salary_query,
                    query: {
                        sanitationSalaryManageVO: {
                            bidId: null,
                            checkStatus: 0,
                            salaryName: null,
                        },
                        pageSize:10,
                        currentPage:1,
                    },
                    // 是否显示分页
                    // hidePagination: true,
                    // 表格查询的条件组件
                    searchData: [{
                            type: "cascader_radio",
                            title: "标段名称",
                            model: "sanitationSalaryManageVO.bidId",
                            placeholder: "请选择",
                            option: []
                        }, {
                            type: 'select',
                            title: '审核状态',
                            model: 'sanitationSalaryManageVO.checkStatus',
                            placeholder: '请选择',
                            option: vm.$enums_hw.checkStatusType.list
                        },{
                            type: "input",
                            model: "sanitationSalaryManageVO.salaryName",
                            placeholder: "工资名称"
                        }        
                    ],
                    columns: [{
                            type: 'selection',
                            width: '50px'
                        },
                        {
                            title: '序号',
                            width: '50px',
                            $index: 'index',
                        },
                        {
                            title: '工资名称',
                            width: '160px',
                            render(h, ctx) {
                                return h('span', [
                                    h('span', {
                                        class: {
                                            'table-view-hw': true
                                        },
                                        on: {
                                            click: vm.onDetails.bind(this, ctx.row)
                                        }
                                    }, ctx.row.salaryName),
                                ])
                            }
                        }, {
                            title: '标段名称',
                            showTooltip: true,
                            key: 'bidName',
                        }, {
                            title: '养护企业',
                            showTooltip: true,
                            key: 'company',
                        }, {
                            title: '发放总额',
                            width: '140px',
                            key: 'salaryTotal',
                        }, {
                            title: '提交时间',
                            key: 'submitTime',
                            // filter: 'str2ymd',
                            width: '170px',
                        }, {
                            title: '审核状态',
                            width: '140px',
                            key: 'checkStatusDes',
                        }, {
                            title: '审核说明',
                            width: '140px',
                            key: 'checkResult',
                        }, {
                            title: '操作',
                            width: '100px',
                            render(h, ctx) {
                                return h('span', [
                                    h('span', {
                                        class: {
                                            'table-view-hw': true
                                        },
                                        on: {
                                            click: vm.onDisposal.bind(this, ctx.row)
                                        }
                                    }, '编辑'),
                                ])
                            }
                        }
                    ]
                },
            }
        },

        methods: {
            //发放工资
            onAdd() {
                this.$router.push({
                    path: '/district-management/salary-management/add-salary',
                })
            },
            //查看
            onDetails(val) {
                this.$router.push({
                    path: '/district-management/salary-management/details-salary',
                    query: {
                        id: val.salaryId
                    }
                })
            },
            // 编辑
            onDisposal(val) {
                this.$router.push({
                    path: '/district-management/salary-management/edit-pay-salaries',
                    query: {
                        id: val.salaryId
                    }
                })
            },
            handleSelectionChange(val) {
                this.selectionData = val.map(item => {
                    return item.salaryId
                })
            },
            async onSubmit() {
                await this.$api_hw.salary_commit({
                    salaryIds: this.selectionData
                })
                await this.$notify({
                    title: '成功',
                    message: '提交成功',
                    type: 'success'
                });
                await this.$search(this.table)
            }
            
        },
        async mounted() {
            await this.$search(this.table)
            this.table.searchData[0].option = await this.$api_hw.common_getBidNameAndIdList({});
        }
    }
</script>
<style lang='sass' scoped>
.btn
  height: 28px
  border: 1px solid $-color-primary-3
  border-radius: 4px
  color: $-color-primary-3
/deep/ .el-button--primary 
    color: #FFFFFF
    background-color: $-color-primary-3
    border-color: $-color-primary-3
/deep/.el-checkbox__input.is-checked .el-checkbox__inner
    background-color: $-color-primary-3
    border-color: $-color-primary-3
/deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner
    background-color: $-color-primary-3
    border-color: $-color-primary-3
/deep/.el-checkbox__inner:hover 
    border-color: $-color-primary-3
.detail-main
  margin: 0px 30px
  font-size: 14px
  font-family: Microsoft YaHei
  font-weight: 400
  color: rgba(102,102,102,1)
  line-height: 46px
</style>